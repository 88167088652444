import { Chip as _Chip, ChipProps as CP, Skeleton, styled } from '@mui/material';

export type ChipProps = Omit<CP, 'label'> & {
  label: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  className?: string;
  loading?: boolean;
};

const C = styled(_Chip)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.8rem',
  height: '1.3rem',
  cursor: 'default',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  border: `2px solid ${theme.palette.action.disabledBackground}`,
}));

export function Chip({ label, loading = false, ...props }: ChipProps) {
  return loading ? <Skeleton /> : <C label={label} {...props} />;
}
