import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext, useContext } from 'react';
import { useApolloClients } from './useApolloClients';

const ClientContext = createContext<{
  managerClient?: ApolloClient<NormalizedCacheObject>;
  systemClient?: ApolloClient<NormalizedCacheObject>;
}>({});

export default function ClientProvider({ children }: { children: React.ReactNode }) {
  const { managerClient, systemClient } = useApolloClients();

  return (
    <ClientContext.Provider
      value={{
        managerClient,
        systemClient,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export const useClient = () => {
  return useContext(ClientContext);
};
