import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Column, Row } from '../types';
import EnhancedCell from './EnhancedCell';

type EnhancedRowProps<TRow extends Row> = {
  row: TRow;
  onRowClick?: (id: string) => void;
  columns: Column<TRow>[];
  loading?: boolean;
};

const StyledRow = styled(TableRow)(({ theme, onClick }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 80,
  ...(onClick ? { cursor: 'pointer' } : {}),
}));

export default function EnhancedRow<TRow extends Row>({
  row,
  onRowClick,
  columns,
  loading,
}: EnhancedRowProps<TRow>) {
  return (
    <StyledRow hover onClick={() => (onRowClick ? onRowClick(row.id) : {})}>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <EnhancedCell<TRow> key={`${row.id}-${column.field}`} {...{ column, row, loading }} />
        ))}
    </StyledRow>
  );
}
