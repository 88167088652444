import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useAuth } from './AuthProvider';

export const useLogin = () => {
  const { signin } = useAuth();

  const federatedSignIn = () =>
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const userInfo = await Auth.currentUserInfo();
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (userInfo.attributes) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const user = {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...userInfo.attributes,
            idToken: idToken.getJwtToken(),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            groups: idToken.payload['cognito:groups'] ?? [],
          };
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          signin(user);
        }
      })
      .catch(() => console.log('Not signed in'));
  }, []);

  return { federatedSignIn };
};
