import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { FilterList, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useAnchorEl } from 'hooks';

export default function GlobalFilter({
  globalFilter,
  globalFilterExcluded,
  onFilterSelected,
}: {
  globalFilter: {
    label: string;
  }[];
  globalFilterExcluded: string[];
  onFilterSelected: (value: string, toExclude: boolean) => void;
}) {
  const { anchorEl, clearAnchorEl, handleAnchorElClick } = useAnchorEl();

  return (
    <>
      <IconButton onClick={handleAnchorElClick} size="large">
        <FilterList fontSize="small" />
      </IconButton>
      <Menu keepMounted {...{ anchorEl, open: Boolean(anchorEl), onClose: clearAnchorEl }}>
        {globalFilter.map(({ label }) => {
          const isExcluded = globalFilterExcluded.includes(label);
          return (
            <MenuItem onClick={() => onFilterSelected(label, !isExcluded)} key={label}>
              <ListItemIcon>
                {!isExcluded ? (
                  <CheckBox fontSize="small" color="primary" />
                ) : (
                  <CheckBoxOutlineBlank fontSize="small" color="primary" />
                )}
              </ListItemIcon>
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
