import { Logout } from '@mui/icons-material';
import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from '@mui/material';
import { useAuth } from 'auth';
import { useTheme } from 'context';
import { MouseEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { avatarInitials } from 'system';

export default function UserMenu() {
  const { user } = useAuth();
  const { darkMode, toggleDarkMode } = useTheme();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      {user && (
        <>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user.name} src={user.picture}>
                {avatarInitials(user.name)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <FormControlLabel
                labelPlacement="start"
                control={<Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />}
                label="Dark mode"
              />
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <Link component={RouterLink} to="/logout">
                Logout
              </Link>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}
