import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropsWithChildren, ReactNode } from 'react';

const CenteredGrid = styled(Grid)({
  height: 'calc(100vh - 73px)', // 73px is the height of the SiteNav
});

export default function CenteredFrame({ children, nav }: PropsWithChildren<{ nav: ReactNode }>) {
  return (
    <CenteredGrid container justifyContent="center" alignItems="center">
      {nav}
      {children}
    </CenteredGrid>
  );
}
