import { ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Column, Row } from '../types';

type EnhancedMobileRowProps<TRow extends Row> = {
  row: TRow;
  onRowClick?: (id: string) => void;
  columns: Column<TRow>[];
  renderMobileRow?: (params: { id: string; value: TRow }) => ReactNode;
};

const StyledRow = styled('div')(({ theme, onClick }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  ...(onClick ? { cursor: 'pointer' } : {}),
}));

const ListItemRow = styled(ListItemButton)(({ theme, onClick }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  ...(onClick ? { cursor: 'pointer' } : {}),
}));

const Item = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

const HeaderText = styled(ListItemText)(() => ({
  flex: 1,
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '1em',
  },
}));

const ContentText = styled(ListItemText)(({ theme }) => ({
  flex: 1,
  '& .MuiListItemText-primary': {
    fontSize: '1em',
    marginBottom: theme.spacing(1),
  },
}));

export default function EnhancedMobileRow<TRow extends Row>({
  row,
  onRowClick,
  columns,
  renderMobileRow,
}: EnhancedMobileRowProps<TRow>) {
  return renderMobileRow ? (
    <StyledRow onClick={() => (onRowClick ? onRowClick(row.id) : {})}>
      {renderMobileRow({ id: row.id, value: row })}
    </StyledRow>
  ) : (
    <ListItemRow onClick={() => (onRowClick ? onRowClick(row.id) : {})}>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <Item key={`${column.field} ${row.id}`}>
            <HeaderText primary={column.headerName} />
            <ContentText
              primary={
                column.renderCell ? (
                  column.renderCell({ id: row.id, value: row[column.field], row })
                ) : (
                  <>{row[column.field]}</>
                )
              }
            />
          </Item>
        ))}
    </ListItemRow>
  );
}
