import { Badge as MUIBadge, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import plur from 'plur';

export type BadgeNumberCellProps = {
  badgeNumber: number;
  text?: string;
  showZero?: boolean;
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1.6),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1em',
  marginLeft: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9em',
  },
}));

const Badge = styled(MUIBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primaryBackground.main,
    padding: theme.spacing(1),
  },
}));

export default function BadgeNumberCell({
  badgeNumber,
  text = '',
  showZero = false,
}: BadgeNumberCellProps) {
  return (
    <Container>
      <Badge badgeContent={badgeNumber} showZero={showZero} />
      {text && (showZero || badgeNumber > 0) && <Text>{plur(text, badgeNumber)}</Text>}
    </Container>
  );
}
