import { Amplify } from '@aws-amplify/core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { awsconfig } from './aws.config';

Amplify.configure(awsconfig);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>
);
