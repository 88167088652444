import { Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type TableToolbarProps = {
  toolbarComponent: ReactNode;
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export default function TableToolbar({ toolbarComponent }: TableToolbarProps) {
  return <StyledToolbar>{toolbarComponent}</StyledToolbar>;
}
