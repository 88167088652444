import { TextField, TextFieldProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { toNumber } from 'lodash';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
        {
          display: 'none',
        },
    },
  })
);

export default function TextFieldController<T extends FieldValues = Record<string, undefined>>({
  name,
  variant,
  helperText,
  className,
  sx,
  ...props
}: TextFieldProps & { name: Path<T> }) {
  const classes = useStyles();
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant={variant ?? 'filled'}
          fullWidth
          {...props}
          {...field}
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(
              props.type === 'number' ? (e.target.value ? toNumber(e.target.value) : e) : e
            );
            props.onChange?.(e);
          }}
          inputProps={{
            ...props.inputProps,
            inputMode: props.type === 'number' ? 'numeric' : 'text',
          }}
          InputProps={{ ...props.InputProps }}
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message || helperText}
          className={clsx(classes.container, className)}
          {...spreadIf(sx, { sx })}
        />
      )}
    />
  );
}
