import { Skeleton, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { skipProps } from 'system';
import { Column, Row } from '../types';

type EnhancedCellProps<TRow extends Row> = {
  row: TRow;
  column: Column<TRow>;
  loading?: boolean;
};

const Cell = styled(
  TableCell,
  skipProps('flex')
)<{ flex: CSSProperties['flex'] }>(({ theme, flex }) => ({
  alignSelf: 'center',
  padding: theme.spacing(2),
  border: 'none',
  flex,
}));

export default function EnhancedCell<TRow extends Row>({
  column,
  row,
  loading,
}: EnhancedCellProps<TRow>) {
  return (
    <Cell className={column.className} flex={column.flex ?? 1}>
      {loading ? (
        <Skeleton />
      ) : column.renderCell ? (
        column.renderCell({ id: row.id, value: row[column.field], row: row })
      ) : (
        <>{row[column.field]}</>
      )}
    </Cell>
  );
}
