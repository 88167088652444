import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { HandlerMap } from '@propra-system/registry';
import { useSystemEventsSubscription } from 'api/system';
import { useClient } from 'apollo/ClientProvider';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { PropsWithChildren, createContext, useContext } from 'react';
import { Children, noOp } from 'system';
import { useEventHandlers } from './useEventHandlers';

const EventsContext = createContext<ReturnType<typeof useEventHandlers>>({
  hashMap: [] as number[],
  handlerMap: [] as HandlerMap,
  addHandler: noOp,
  setEvent: noOp,
  addHandlerMap: noOp,
});

export const useEvents = () => useContext(EventsContext);

const LocalEventsProvider = ({
  children,
  client,
}: PropsWithChildren<{ client: ApolloClient<NormalizedCacheObject> }>) => {
  const value = useEventHandlers({ systemClient: client });
  const { setEvent } = value;
  const { error: eventsError } = useSystemEventsSubscription({
    onData: ({ data }) => {
      data.data?.onSystemEvent && setEvent(data.data.onSystemEvent);
    },
    client,
  });
  useAllErrors(eventsError);
  return <EventsContext.Provider {...{ value }}>{children}</EventsContext.Provider>;
};

export default function EventsProvider({ children }: Children) {
  const { systemClient } = useClient();

  return systemClient ? (
    <LocalEventsProvider client={systemClient}>{children}</LocalEventsProvider>
  ) : (
    <></>
  );
}
