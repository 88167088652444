const omitNonSearchFields = (key: string, value: unknown) => key !== 'id' && value;

export const filterByValue = <T = unknown>(arr: Array<T>, value: string) => {
  return value === ''
    ? arr
    : arr.filter((data) =>
        value
          .toLowerCase()
          .split(' ')
          .every(
            (valstr) =>
              JSON.stringify(data, omitNonSearchFields)
                .toLowerCase()
                .indexOf(valstr.replace(/[^-.\w\d'’]/gi, '')) !== -1
          )
      );
};
