import { TableCell as MUITableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { ReactNode } from 'react';
import { skipProps } from 'system';
import { Order } from '../types';

type EnhancedCellProps = {
  flex: CSSProperties['flex'];
  children?: ReactNode;
  showBorder: boolean;
  sortDirection?: Order | false;
};

const TableCell = styled(
  MUITableCell,
  skipProps('flex', 'showBorder')
)<{ flex: CSSProperties['flex']; showBorder?: boolean }>(({ theme, flex, showBorder }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  border: 'none',
  padding: theme.spacing(2, 1, 2, 2),
  flex,
  ...(showBorder ? { borderRight: `1px solid ${theme.palette.divider}` } : {}),
}));

export default function TableHeadCell({
  flex,
  children,
  showBorder,
  sortDirection,
}: EnhancedCellProps) {
  return (
    <TableCell
      align="left"
      padding="normal"
      sortDirection={sortDirection ? sortDirection : false}
      {...{ flex, showBorder }}
    >
      {children}
    </TableCell>
  );
}
