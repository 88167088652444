import { Hidden } from '@mui/material';
import clsx from 'clsx';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import { AdaptiveTableProps } from '../types';

export default function AdaptiveTable(props: AdaptiveTableProps) {
  return (
    <div className={clsx(props.className)}>
      <Hidden mdUp>
        <MobileTable {...props} />
      </Hidden>
      <Hidden mdDown>
        <DesktopTable {...props} />
      </Hidden>
    </div>
  );
}
