import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow as MaterialTableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { skipProps } from 'system';
import { AdaptiveTableProps } from '../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableToolbar from './TableToolbar';

const Root = styled('div')({ width: '100%' });
const MarginPaper = styled(
  Paper,
  skipProps('hasMargins')
)<{ hasMargins?: boolean }>(({ theme, hasMargins }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
  ...(hasMargins ? {} : { margin: 0 }),
}));

const Row = styled(MaterialTableRow)(({ theme }) => ({
  height: theme.spacing(6.625),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  alignSelf: 'center',
  padding: theme.spacing(2),
  border: 'none',
}));

export default function DesktopTable({
  rows,
  columns,
  onRowClick,
  loading,
  error,
  toolbarComponent,
  footerComponent,
  header = 'always',
  cellPadding,
  hasMargins = true,
  condensed,
}: AdaptiveTableProps) {
  const showHeader = header === 'always' || header === 'desktopOnly';

  return (
    <Root>
      <MarginPaper hasMargins={hasMargins} elevation={0}>
        <TableContainer>
          {toolbarComponent && (
            <TableToolbar
              {...{
                toolbarComponent,
              }}
            />
          )}
          <Table aria-labelledby="tableTitle" size="medium" aria-label="adaptive table">
            {showHeader && <TableHead columns={columns.filter(({ hidden }) => !hidden)} />}
            <TableBody>
              {loading && (
                <Row>
                  <Cell>
                    <Skeleton />
                  </Cell>
                </Row>
              )}
              {!loading && error && (
                <Row>
                  <Cell>{JSON.stringify(error)}</Cell>
                </Row>
              )}
              {!loading &&
                !error &&
                rows.map((row) => (
                  <TableRow
                    key={row.id}
                    {...{
                      row,
                      onRowClick,
                      condensed,
                    }}
                    columns={columns.filter(({ hidden }) => !hidden)}
                    cellPadding={cellPadding}
                  />
                ))}
            </TableBody>
            {footerComponent && (
              <TableFooter>
                <tr>
                  <td colSpan={99}>{footerComponent}</td>
                </tr>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </MarginPaper>
    </Root>
  );
}
