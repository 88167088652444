import { FieldMergeFunction, FieldReadFunction, InMemoryCache } from '@apollo/client';
import { keyBy } from 'lodash';

type Connection = { items?: { id: string }[]; nextToken?: string };

const mergeItemsWith =
  <T>(getKey: (item: T) => string) =>
  (existing: T[] = [], incoming: T[] = []) =>
    Object.values({
      ...keyBy(existing, getKey),
      ...keyBy(incoming, getKey),
    });

// I don't know why this is necessary but without it `connectionMerge` doesn't seem to work
const connectionRead: FieldReadFunction<Connection> = (existing) => existing;
const connectionMerge: (fields?: string[]) => FieldMergeFunction<Connection> =
  (fields = ['id']) =>
  (existing, incoming, { readField }) => {
    const asKey = (item: NonNullable<Connection['items']>[0]) =>
      fields.map((field) => readField(field, item)).join();

    const items = mergeItemsWith(asKey)(existing?.items, incoming?.items);

    return {
      items,
      nextToken: incoming.nextToken,
    };
  };

const cacheFirst: (__typename: string) => { read: FieldReadFunction } = (__typename: string) => ({
  read: (_, { args, toReference }) =>
    toReference({ __typename, id: args?.id ? String(args?.id) : 'invalid' }),
});

export const cache = new InMemoryCache({
  typePolicies: {
    PropertyFact: { keyFields: ['propertyId', 'dateKey'] },
    AccountFact: { keyFields: ['accountId', 'dateKey'] },
    IamQuery: {
      fields: {
        listAccountFacts: {
          keyArgs: ['filter'],
          merge: connectionMerge(['dateKey', 'accountId']),
          read: connectionRead,
        },
      },
    },
    Query: {
      fields: {
        iamQuery: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          read: (existing) => existing,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
        scannerResults: {
          merge: connectionMerge(),
          read: connectionRead,
        },
        listAutopays: {
          merge: connectionMerge(),
          read: connectionRead,
        },
        autopay: cacheFirst('Autopay'),
      },
    },
  },
});
