import { TableCell as MaterialTableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { skipProps } from 'system';
import { AdaptiveColumn, Row } from '../types';

type TableCellProps = {
  row: Row;
  column: AdaptiveColumn;
  cellPadding?: number;
};

const Cell = styled(
  MaterialTableCell,
  skipProps('minWidth')
)<{
  flex?: CSSProperties['flex'];
  padding?: CSSProperties['padding'];
  minWidth?: number;
}>(({ theme, flex = 1, padding = 2, minWidth }) => ({
  alignSelf: 'center',
  margin: theme.spacing(2, 1, 2, padding ?? 2),
  border: 'none',
  flex,
  padding: 0,
  minWidth,
}));

export default function TableCell({ column, row }: TableCellProps) {
  return (
    <Cell
      align="left"
      flex={column.flex}
      minWidth={column?.minWidth}
      padding="normal"
      sx={column.sx}
    >
      {column.renderCell ? (
        column.renderCell({ id: row.id, value: row[column.field], row })
      ) : (
        <>{row[column.field]}</>
      )}
    </Cell>
  );
}
