import { ApolloClient, HttpLink, from } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { awsconfig } from 'aws.config';
import { useNotification } from 'hooks';
import { cache } from './cache';
import cleanVariablesLink from './cleanVariablesLink';
import { errorLink } from './errorLink';

const region = awsconfig.Auth.region ?? 'us-west-2';
const auth = { type: 'AWS_IAM' as const, credentials: Auth.currentUserCredentials.bind(Auth) };

const SYSTEM_URL = process.env.REACT_APP_SYSTEM_APPSYNC_GRAPHQL ?? 'http://invalid';
const systemAuthLink = createAuthLink({ url: SYSTEM_URL, region, auth });
const systemHttpLink = createSubscriptionHandshakeLink(
  { url: SYSTEM_URL, region, auth },
  new HttpLink({ uri: SYSTEM_URL })
);

const MANAGER_URL = process.env.REACT_APP_MANAGER_APPSYNC_GRAPHQL ?? 'http://invalid';
const managerAuthLink = createAuthLink({ url: MANAGER_URL, region, auth });
const managerHttpLink = new HttpLink({ uri: MANAGER_URL });

export const useApolloClients = () => {
  const { sendNotification } = useNotification();
  const notifier = (message: Parameters<typeof sendNotification>[0]) => {
    console.error(message);
    sendNotification(message, 'error');
  };

  const managerClient = new ApolloClient({
    link: from([errorLink(notifier), cleanVariablesLink, managerAuthLink, managerHttpLink]),
    cache,
  });

  const systemClient = new ApolloClient({
    link: from([errorLink(notifier), cleanVariablesLink, systemAuthLink, systemHttpLink]),
    cache,
  });

  return { managerClient, systemClient };
};
