import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropsWithChildren, ReactNode } from 'react';

const PageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 4, 4),
}));

export default function DefaultFrame({ children, nav }: PropsWithChildren<{ nav: ReactNode }>) {
  return (
    <PageBox>
      {nav}
      {children}
    </PageBox>
  );
}
