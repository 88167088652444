import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Hidden } from '@mui/material';
import { EnhancedTableState } from 'cache';
import { useSearchRows } from 'hooks';
import { isEqual } from 'lodash';
import { FC, ReactNode, useEffect } from 'react';
import { Column, GlobalFilter, Row } from '../types';
import EnhancedDesktopTable from './EnhancedDesktopTable';
import EnhancedMobileTable from './EnhancedMobileTable';
import { getComparator, stableSort } from './utils';

export type EnhancedTableProps<TRow extends Row> = {
  rows: TRow[];
  columns: Column<TRow>[];
  onRowClick?: (id: string) => void;
  allowPagination?: boolean;
  allowSearch?: boolean;
  allowSort?: boolean;
  allowExport?: boolean;
  className?: string;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode;
  footerComponent?: ReactNode;
  rowExpansionComponent?: FC<{ row: TRow }>;
  renderMobileRow?: (params: { id: string; value: TRow }) => ReactNode;
  globalFilter?: GlobalFilter<TRow>;
  loading?: boolean;
  emptyState?: JSX.Element;
};

export default function EnhancedTable<TRow extends Row = Row>({
  className,
  allowSearch,
  allowSort,
  rows,
  columns,
  reactiveState,
  globalFilter,
  emptyState,
  ...props
}: EnhancedTableProps<TRow>) {
  const state = useReactiveVar(reactiveState);

  const {
    search,
    filteredRowIds,
    orderBy,
    sortField,
    order,
    filteredColumns,
    globalFilterExcluded,
  } = state;

  const { searchedRows } = useSearchRows({ rows, searchTerm: allowSearch ? search : undefined });

  const sortedRows =
    allowSort && orderBy
      ? stableSort(searchedRows, getComparator(order, sortField ?? orderBy))
      : searchedRows;

  const filteredByColumnsRows = sortedRows.filter((row) => {
    const valuesToRemove = filteredColumns.map((filteredColumn) => filteredColumn.excludes).flat();
    return !valuesToRemove.some((valueToRemove) => Object.values(row).includes(valueToRemove));
  });

  const filteredRows =
    globalFilter && globalFilterExcluded.length > 0
      ? filteredByColumnsRows.filter((row) => {
          const filterToTestForTrue = globalFilter.filter(
            (filter) => !globalFilterExcluded.includes(filter.label)
          );
          const results = filterToTestForTrue.map(({ test }) => test(row));
          return results.some((result) => result);
        })
      : filteredByColumnsRows;

  useEffect(() => {
    const currentFilteredRowIds = filteredRows.map((row) => row.id);
    if (!isEqual(filteredRowIds, currentFilteredRowIds)) {
      reactiveState({
        ...state,
        filteredRowIds: currentFilteredRowIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  return !props.loading && rows.length === 0 && emptyState ? (
    emptyState
  ) : (
    <div className={className}>
      <Hidden mdUp>
        <EnhancedMobileTable<TRow>
          {...props}
          rows={filteredRows}
          {...{ columns, allowSearch, reactiveState }}
        />
      </Hidden>
      <Hidden mdDown>
        <EnhancedDesktopTable<TRow>
          {...props}
          rows={filteredRows}
          {...{ columns, allowSearch, allowSort, reactiveState, globalFilter }}
        />
      </Hidden>
    </div>
  );
}
