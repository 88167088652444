import { SvgIcon, SvgIconProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& path': {
        color: theme.palette.tertiary.main,
      },
    },
  })
);

export default function PropraBuilding({ className, ...props }: SvgIconProps) {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 54 70" className={clsx(className, classes.root)} {...props}>
      <path d="M46.75 39.625H53.375V0.875H18.375V19H0.25V69.125H6.875V25.625H25V7.5H46.75V39.625Z" />
      <path d="M21.375 32.8749H14.125V40.1249H21.375V32.8749Z" />
      <path d="M39.375 46.875H32.125V54.125H39.375V46.875Z" />
      <path d="M39.375 32.875H32.125V40.125H39.375V32.875Z" fill="#24D1AD" />
      <path d="M39.5 11.1249H32.25V18.3749H39.5V11.1249Z" />
      <path d="M21.375 47.375H14.125V54.625H21.375V47.375Z" fill="#24D1AD" />
      <path d="M21.375 61.8751H14.125V69.1251H21.375V61.8751Z" />
    </SvgIcon>
  );
}
