import { TableRow as MaterialTableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { skipProps } from 'system';
import { AdaptiveColumn, Row } from '../types';
import TableCell from './TableCell';

type TableRowProps = {
  row: Row;
  onRowClick?: (id: string, row: Row) => void;
  columns: AdaptiveColumn[];
  cellPadding?: number;
  condensed?: boolean;
};

const StyledRow = styled(
  MaterialTableRow,
  skipProps('condensed')
)<{ condensed?: boolean }>(({ theme, onClick, condensed }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 80,
  padding: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(onClick ? { cursor: 'pointer' } : {}),
  ...(condensed ? { minHeight: 'inherit' } : {}),
}));

export default function TableRow({
  row,
  onRowClick,
  columns,
  cellPadding,
  condensed,
}: TableRowProps) {
  return (
    <StyledRow hover onClick={() => onRowClick?.(row.id, row)} condensed={condensed}>
      {columns.map((column) => (
        <TableCell
          key={`${row.id}-${column.field}`}
          {...{ column, row }}
          cellPadding={cellPadding}
        />
      ))}
    </StyledRow>
  );
}
