import { ListItemButton, ListItemText, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Column, Row } from '../types';

const ListItemRow = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
}));

const Item = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

const ContentText = styled(ListItemText)(() => ({
  flex: 1,
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '1em',
  },
}));

export default function EnhancedMobileRowPlaceholder<TRow extends Row>({
  key,
  columns,
}: {
  key: string | number;
  columns: Column<TRow>[];
}) {
  return (
    <ListItemRow>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <Item key={`${key} ${column.field}`}>
            <ContentText primary={column.headerName} />
            <Skeleton sx={{ flex: 2 }} />
          </Item>
        ))}
    </ListItemRow>
  );
}
