import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { LoadingButton } from 'components';
import { ReactNode } from 'react';
import { colours } from 'system';
import { LoadingButtonProps } from './LoadingButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 375,
      [theme.breakpoints.down('sm')]: {
        width: 320,
      },
    },
    contentPadded: {
      padding: theme.spacing(0, 3),
    },
    double: {
      width: 600,
      maxWidth: '100%',
    },
    customWidth: ({ width }: { width: number }) => ({
      width: width,
      maxWidth: '100%',
    }),
    fullScreen: {
      padding: '0 !important',
      width: '100%',
      heigh: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      padding: theme.spacing(3),
    },
    cancel: {
      color: colours.nobel,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  })
);

type ModalAction = {
  label: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export type ModalProps = {
  onClose: VoidFunction;
  open: boolean;
  title?: string;
  children: ReactNode;
  double?: boolean;
  hasPadding?: boolean;
  actions?: {
    cancel?: ModalAction;
    confirm?: ModalAction;
  };
  customWidth?: number;
} & DialogProps;

const loadingButtonProps = ({ onClick, loading, disabled }: ModalAction): LoadingButtonProps => ({
  onClick,
  loading,
  disabled,
});

export default function Modal({
  open,
  onClose,
  title,
  children,
  actions,
  double,
  customWidth,
  hasPadding = true,
  ...dialogProps
}: ModalProps) {
  const classes = useStyles({ width: customWidth ?? 375 });
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
      <DialogContent
        className={clsx({
          [classes.fullScreen]: dialogProps.fullScreen,
          [classes.content]: !dialogProps.fullScreen && !customWidth,
          [classes.double]: !dialogProps.fullScreen && !customWidth && double,
          [classes.customWidth]: !dialogProps.fullScreen && customWidth,
          [classes.contentPadded]: hasPadding,
        })}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.actions}>
          {actions.cancel && (
            <LoadingButton
              color={!actions.confirm?.disabled ? 'primary' : 'secondary'}
              className={clsx(actions.confirm && !actions.confirm?.disabled && classes.cancel)}
              {...loadingButtonProps(actions.cancel)}
            >
              {actions.cancel.label}
            </LoadingButton>
          )}
          {actions.confirm && (
            <LoadingButton color="primary" {...loadingButtonProps(actions.confirm)}>
              {actions.confirm.label}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
