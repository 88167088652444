import { List, ListItemButton, ListItemText, Paper, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdaptiveTableProps } from '../types';
import TableToolbar from './TableToolbar';

const Root = styled('div')({ width: '100%' });
const WidePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
}));

const DivRow = styled('div')(({ theme, onClick }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  ...(onClick ? { cursor: 'pointer' } : {}),
}));

const ListItemRow = styled(ListItemButton)(({ theme, onClick }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  ...(onClick ? { cursor: 'pointer' } : {}),
}));

const ColumnCell = styled(ListItemText)(({ theme }) => ({
  alignSelf: 'center',
  padding: theme.spacing(2),
  border: 'none',
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '1em',
  },
}));

const RowCell = styled(ListItemText)(({ theme }) => ({
  flex: 2,
  '& .MuiListItemText-primary': {
    fontSize: '1em',
    marginBottom: theme.spacing(1),
  },
}));

const ListTable = styled(List)(() => ({
  padding: 0,
}));

const DivItem = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

export default function MobileTable({
  rows,
  columns,
  onRowClick,
  loading,
  error,
  toolbarComponent,
  footerComponent,
  header = 'always',
}: AdaptiveTableProps) {
  const showHeader = header === 'always' || header === 'mobileOnly';

  return (
    <Root>
      <WidePaper elevation={0}>
        {toolbarComponent && (
          <TableToolbar
            {...{
              toolbarComponent,
            }}
          />
        )}
        {loading && (
          <DivRow>
            <Skeleton />
          </DivRow>
        )}
        {!loading && error && <DivRow>{JSON.stringify(error)}</DivRow>}
        {!loading && !error && (
          <ListTable>
            {rows.map((row) => (
              <ListItemRow key={row.id} onClick={() => onRowClick?.(row.id, row)}>
                {columns
                  .filter(({ hidden }) => !hidden)
                  .map((column) => (
                    <DivItem key={`${column.field} ${row.id}`}>
                      {showHeader && <ColumnCell primary={column.headerName} />}
                      <RowCell
                        primary={
                          column.renderCell ? (
                            column.renderCell({ id: row.id, value: row[column.field], row })
                          ) : (
                            <>{row[column.field]}</>
                          )
                        }
                      />
                    </DivItem>
                  ))}
              </ListItemRow>
            ))}
          </ListTable>
        )}
        {footerComponent && <div>{footerComponent}</div>}
      </WidePaper>
    </Root>
  );
}
