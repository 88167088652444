import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { List as MUIList, Paper as MUIPaper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EnhancedTableState } from 'cache';
import { ReactNode } from 'react';
import { Column, Row } from '../types';
import EnhancedMobileRow from './EnhancedMobileRow';
import EnhancedMobileRowPlaceholder from './EnhancedMobileRowPlaceholder';
import EnhancedTableToolbar from './EnhancedTableToolbar';

const Root = styled('div')({ width: '100%' });
const List = styled(MUIList)({ padding: 0 });
const Paper = styled(MUIPaper)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
}));

export type EnhancedMobileTableProps<TRow extends Row> = {
  rows: TRow[];
  columns: Column<TRow>[];
  onRowClick?: (id: string) => void;
  allowSearch?: boolean;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode;
  footerComponent?: ReactNode;
  renderMobileRow?: (params: { id: string; value: TRow }) => ReactNode;
  loading?: boolean;
};

export default function EnhancedMobileTable<TRow extends Row>({
  rows,
  columns,
  onRowClick,
  allowSearch = false,
  reactiveState,
  toolbarComponent,
  footerComponent,
  renderMobileRow,
  loading,
}: EnhancedMobileTableProps<TRow>) {
  const state = useReactiveVar(reactiveState);
  const { search } = state;

  return (
    <Root>
      <Paper elevation={0}>
        {Boolean(allowSearch || toolbarComponent) && (
          <EnhancedTableToolbar
            {...{
              allowSearch,
              search,
              updateSearch: (search: string) => reactiveState({ ...state, search, page: 0 }),
              toolbarComponent,
            }}
          />
        )}
        {loading ? (
          <List>
            {[1, 2, 3].map((i) => (
              <EnhancedMobileRowPlaceholder<TRow> key={i} columns={columns} />
            ))}
          </List>
        ) : (
          <List>
            {rows.map((row) => (
              <EnhancedMobileRow<TRow>
                key={row.id}
                {...{ row, onRowClick, columns, renderMobileRow }}
              />
            ))}
          </List>
        )}
        {footerComponent && <div>{footerComponent}</div>}
      </Paper>
    </Root>
  );
}
