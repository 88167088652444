import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { FieldPathValue, Path } from 'react-hook-form';

export type DateLike = string | Date | DateTime | number;

export type Children = { children: ReactNode };

export type NonNullableField<
  Map extends Record<string, unknown>,
  Breadcrumbs extends Path<Map>,
> = NonNullable<FieldPathValue<Map, Breadcrumbs>>;

type UnionToIntersectionHelper<U> = (U extends unknown ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

export type UnionToIntersection<U> = boolean extends U
  ? UnionToIntersectionHelper<Exclude<U, boolean>> & boolean
  : UnionToIntersectionHelper<U>;

export type Option = {
  id: string;
  text: string;
  subText?: string;
  disabled?: boolean;
  disabledMessage?: string;
};

export type Merge<T> = { [K in keyof T]: T[K] };

export type ExcludeNulls<T> =
  T extends Record<string, unknown>
    ? {
        [K in keyof T]: ExcludeNulls<ExcludeNulls<T[K]>>;
      }
    : T extends null
      ? never
      : T;

export type Country = keyof typeof Country;
export const Country = { US: 'US', CA: 'CA' } as const;
export const CountryDropdown = [
  { label: 'United States', value: Country.US },
  { label: 'Canada', value: Country.CA },
];

export const ProvincesByCountry = {
  US: {
    AL: 'AL',
    AK: 'AK',
    AZ: 'AZ',
    AR: 'AR',
    CA: 'CA',
    CO: 'CO',
    CT: 'CT',
    DE: 'DE',
    FL: 'FL',
    GA: 'GA',
    HI: 'HI',
    ID: 'ID',
    IL: 'IL',
    IN: 'IN',
    IA: 'IA',
    KS: 'KS',
    KY: 'KY',
    LA: 'LA',
    ME: 'ME',
    MD: 'MD',
    MA: 'MA',
    MI: 'MI',
    MN: 'MN',
    MS: 'MS',
    MO: 'MO',
    MT: 'MT',
    NE: 'NE',
    NV: 'NV',
    NH: 'NH',
    NJ: 'NJ',
    NM: 'NM',
    NY: 'NY',
    NC: 'NC',
    ND: 'ND',
    OH: 'OH',
    OK: 'OK',
    OR: 'OR',
    PA: 'PA',
    RI: 'RI',
    SC: 'SC',
    SD: 'SD',
    TN: 'TN',
    TX: 'TX',
    UT: 'UT',
    VT: 'VT',
    VA: 'VA',
    WA: 'WA',
    WV: 'WV',
    WI: 'WI',
    WY: 'WY',
  },
  CA: {
    AB: 'AB',
    BC: 'BC',
    MB: 'MB',
    NT: 'NT',
    QC: 'QC',
    SK: 'SK',
    YT: 'YT',
    NU: 'NU',
    ON: 'ON',
    NB: 'NB',
    NL: 'NL',
    NS: 'NS',
    PE: 'PE',
  },
} as const;

export const TimezonesByCountry = {
  [Country.US]: {
    AL: 'America/Chicago',
    AK: 'America/Anchorage',
    AZ: 'America/Phoenix',
    AR: 'America/Chicago',
    CA: 'America/Los_Angeles',
    CO: 'America/Denver',
    CT: 'America/New_York',
    DE: 'America/New_York',
    FL: 'America/New_York',
    GA: 'America/New_York',
    HI: 'Pacific/Honolulu',
    ID: 'America/Denver',
    IL: 'America/Chicago',
    IN: 'America/Indianapolis',
    IA: 'America/Chicago',
    KS: 'America/Chicago',
    KY: 'America/New_York',
    LA: 'America/Chicago',
    ME: 'America/New_York',
    MD: 'America/New_York',
    MA: 'America/New_York',
    MI: 'America/New_York',
    MN: 'America/Chicago',
    MS: 'America/Chicago',
    MO: 'America/Chicago',
    MT: 'America/Denver',
    NE: 'America/Chicago',
    NV: 'America/Los_Angeles',
    NH: 'America/New_York',
    NJ: 'America/New_York',
    NM: 'America/Denver',
    NY: 'America/New_York',
    NC: 'America/New_York',
    ND: 'America/Chicago',
    OH: 'America/New_York',
    OK: 'America/Chicago',
    OR: 'America/Los_Angeles',
    PA: 'America/New_York',
    RI: 'America/New_York',
    SC: 'America/New_York',
    SD: 'America/Chicago',
    TN: 'America/Chicago',
    TX: 'America/Chicago',
    UT: 'America/Denver',
    VT: 'America/New_York',
    VA: 'America/New_York',
    WA: 'America/Los_Angeles',
    WV: 'America/New_York',
    WI: 'America/Chicago',
    WY: 'America/Denver',
  },
  [Country.CA]: {
    AB: 'America/Edmonton',
    BC: 'America/Vancouver',
    MB: 'America/Winnipeg',
    NT: 'America/Edmonton',
    QC: 'America/Toronto',
    SK: 'America/Regina',
    YT: 'America/Edmonton',
    NU: 'America/Winnipeg',
    ON: 'America/Toronto',
    NB: 'America/Halifax',
    NL: 'America/St_Johns',
    NS: 'America/Halifax',
    PE: 'America/Halifax',
  },
} as const;

export const ProvincesDropdownFor = (country?: string) =>
  country && Object.keys(Country).includes(country)
    ? Object.values(ProvincesByCountry[country as Country]).map((type) => ({
        label: type,
        value: type,
      }))
    : [];

export const TimezonesDropdownFor = (country?: string) =>
  country && Object.keys(Country).includes(country)
    ? uniqBy(
        Object.values(TimezonesByCountry[country as Country]).map((type) => ({
          label: type,
          value: type,
        })),
        'value'
      )
    : [];

export const Timezones = TimezonesByCountry.CA;
export const Provinces = ProvincesByCountry.CA;
export const States = ProvincesByCountry.US;
export const ProvincesDropdown = ProvincesDropdownFor('CA');
