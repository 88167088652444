import { Box, Skeleton, Theme, useTheme } from '@mui/material';
import { colours } from 'system';
import { Chip, ChipProps } from './Chip';

const color = (theme: Theme, status: string) =>
  ({
    DISABLED: colours.white,
    ENABLED: colours.nero,
    TEST: theme.palette.text.primary,
  })[status] ?? theme.palette.text.primary;

const backgroundColor = (theme: Theme, status: string) =>
  ({
    ENABLED: theme.palette.success.main,
    DISABLED: theme.palette.error.main,
    TEST: theme.palette.warning.main,
  })[status] ?? 'transparent';

const borderColor = (theme: Theme, status: string) =>
  ({
    DEFAULT: theme.palette.text.secondary,
    ENABLED: theme.palette.success.main,
    DISABLED: theme.palette.error.main,
    TEST: theme.palette.warning.main,
    ADMIN: theme.palette.primary.main,
    'BOOKS ADMIN': colours.shamrock,
    'BOOKS USER': colours.shamrock,
    USER: theme.palette.text.secondary,
  })[status] ?? theme.palette.action.disabledBackground;

export type StatusChipProps = {
  status?: string;
  loading?: boolean;
};

export default function StatusChip({
  status = 'DEFAULT',
  loading = false,
  ...props
}: StatusChipProps & Omit<ChipProps, 'label'>) {
  const theme = useTheme();

  return loading ? (
    <Skeleton />
  ) : (
    <Box sx={{ width: 'fitContent' }}>
      <Chip
        {...props}
        label={status}
        sx={{
          color: color(theme, status),
          backgroundColor: backgroundColor(theme, status),
          borderColor: borderColor(theme, status),
          ...props.sx,
        }}
      />
    </Box>
  );
}
