import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ClientProvider } from 'apollo';
import { AuthProvider } from 'auth';
import { EventsProvider, ThemeProvider } from 'context';
import { Navigation } from 'navigation';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline enableColorScheme />
        <SnackbarProvider>
          <AuthProvider>
            <ClientProvider>
              <EventsProvider>
                <Navigation />
              </EventsProvider>
            </ClientProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
