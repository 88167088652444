import { Auth } from '@aws-amplify/auth';
import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import { createContext, useContext, useState } from 'react';
import { noOp } from 'system';
import { AuthContextType, AuthUser } from './types';

const AuthContext = createContext<AuthContextType>({ signin: noOp, signout: noOp });

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const cachedUser = Cache.getItem('user');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const [user, setUser] = useState<AuthUser | undefined>(cachedUser);

  const signin = (user: AuthUser) => {
    setUser(user);
    Cache.setItem('user', user);
  };

  const signout = () => {
    setUser(undefined);
    Cache.removeItem('user');
    void Auth.signOut();
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
