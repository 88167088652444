import { Hub, Logger } from '@aws-amplify/core';

export const awsconfig = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_COGNITO_SIGNIN_REDIRECT,
      redirectSignOut: process.env.REACT_APP_COGNITO_SIGNOUT_REDIRECT,
      responseType: 'code',
    },
  },
  Storage: {
    customPrefix: {
      public: '',
      protected: '',
      private: '',
    },
    AWSS3: {
      bucket: `propra-data-${process.env.REACT_APP_ENV}`,
      region: process.env.REACT_APP_REGION,
    },
  },
};

const logger = new Logger('My-Logger');

const listener = (data) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in');
      break;
    case 'signUp':
      logger.info('user signed up');
      break;
    case 'signOut':
      logger.info('user signed out');
      break;
    case 'signIn_failure':
      logger.error('user sign in failed');
      break;
    case 'tokenRefresh':
      logger.info('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      logger.error('token refresh failed');
      break;
    case 'configured':
      logger.info('the Auth module is configured');
  }
};

Hub.listen('auth', listener);
