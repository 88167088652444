import { camelCase, get, isObject } from 'lodash';
import Papa, { ParseStepResult } from 'papaparse';
import { useCallback, useState } from 'react';
import { z } from 'zod';

type CsvParserState<TModel> =
  | [state: 'invalid', error: string[]]
  | [state: 'processing']
  | [state: 'valid', data: TModel[]]
  | [];

const coerceNullToUndefined = (
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  src: null | unknown
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
): undefined | Record<string, Exclude<null | unknown, null>> =>
  isObject(src)
    ? Object.fromEntries(Object.entries(src).map(([k, v]) => [k, v === null ? undefined : v]))
    : undefined;

type ParserParams<T> = {
  abortOnError?: boolean;
  step?: (result: ParseStepResult<T>, parser: Papa.Parser) => void;
  complete?: (result: Papa.ParseResult<T>, file: Papa.LocalFile) => void;
} & Omit<Parameters<typeof Papa.parse<T>>[1], 'complete' | 'step'>;

export const useParseCsv = <
  TSchema extends z.AnyZodObject = z.AnyZodObject,
  TOutput = TSchema extends z.ZodType<infer Output> ? Output : unknown,
>({ schema }: { schema?: TSchema } = {}) => {
  const [state, setState] = useState<CsvParserState<TOutput>>([]);

  const parseCsv = useCallback(
    async (
      files: File[],
      { abortOnError = true, step, complete, ...params }: ParserParams<TOutput>
    ) => {
      setState(['processing']);

      return new Promise<CsvParserState<TOutput>>((resolve) => {
        let csvIndex = 2; // offset header row using 1-based indexing
        const parsedRows: TOutput[] = [];
        let nextState: CsvParserState<TOutput> = [];

        Papa.parse(files[0], {
          header: true,
          dynamicTyping: true,
          transformHeader: camelCase,
          skipEmptyLines: true,
          ...params,
          step: (stepResult, parser) => {
            // Lock current index to avoid parallel parses increasing this value before we use it
            const index = csvIndex++;

            try {
              const row = stepResult.data;

              parsedRows.push(
                (schema?.parse(coerceNullToUndefined(row)) ?? coerceNullToUndefined(row)) as TOutput
              );
            } catch (e) {
              if (nextState[0] === 'invalid') {
                nextState[1].push(`Row #${index}: ${get(e, 'message') ?? JSON.stringify(e)}`);
              } else {
                nextState = [
                  'invalid',
                  [`Row #${index}: ${get(e, 'message') ?? JSON.stringify(e)}`],
                ];
              }

              abortOnError && parser.abort();
              step?.(stepResult, parser);
            }
          },
          complete: (result, file) => {
            if (!result.errors.length && !result.meta.aborted) {
              nextState = ['valid', parsedRows];
            }

            setState(nextState);
            resolve(nextState);
            complete?.({ ...result, data: parsedRows }, file);
          },
        });
      });
    },
    [schema]
  );

  const stateIs = useCallback((type?: CsvParserState<TOutput>[0]) => state[0] === type, [state]);

  return {
    state,
    stateIs,
    parseCsv,
  };
};
