import { DateTime } from 'luxon';

type ObjectWithName = { name: string };
type ObjectWithDate = { date: DateTime };
type ObjectWithStartISO = { start?: string };
type SortableTenant = { leaseHolder: boolean; name: string };
type StringKeys<T> = { [k in keyof T]: T[k] extends string ? k : never }[keyof T];

export const sortByName = (a: ObjectWithName, b: ObjectWithName) => {
  if (!isNaN(parseFloat(a.name)) && !isNaN(parseFloat(b.name))) {
    return parseFloat(a.name) - parseFloat(b.name);
  }

  return a.name.localeCompare(b.name);
};

export const sortByAttribute =
  <T>(attribute: StringKeys<T>) =>
  (a: T, b: T) => {
    const a_ = a[attribute];
    const b_ = b[attribute];

    if (typeof a_ === 'string' && typeof b_ === 'string') {
      if (!isNaN(parseFloat(a_)) && !isNaN(parseFloat(b_))) {
        return parseFloat(a_) - parseFloat(b_);
      }

      return a_.localeCompare(b_);
    }

    return 0;
  };

export const sortOptionsByText = <T extends { text: string }>(arr: T[]) => {
  return arr.sort((a, b) => a.text.localeCompare(b.text));
};

export const sortDataByLabel = <T extends { label: string }>(arr: T[]) => {
  return arr.sort((a, b) => a.label.localeCompare(b.label));
};

export const leaseHoldersFirst = (a: SortableTenant, b: SortableTenant): number =>
  a.leaseHolder === b.leaseHolder ? a.name.localeCompare(b.name) : a.leaseHolder ? -1 : 1;

export const newestToOldest = ({ date: a }: ObjectWithDate, { date: b }: ObjectWithDate) =>
  a < b ? 1 : a > b ? -1 : 0;

export const byStart = (
  { start: a = '' }: ObjectWithStartISO,
  { start: b = '' }: ObjectWithStartISO
) => (a > b ? 1 : a < b ? -1 : 0);

export const chronological = (a: DateTime, b: DateTime) => (a > b ? 1 : a < b ? -1 : 0);

export const byAssetName = (
  a: { name: string } | { name: string; property: { name: string } },
  b: { name: string } | { name: string; property: { name: string } }
) =>
  'property' in a && 'property' in b && a.property.name !== b.property.name
    ? sortByName(a.property, b.property)
    : sortByName(a, b);
