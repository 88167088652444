import * as Icons from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
  Drawer as _Drawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthUser, useAuth } from 'auth';
import { Logo } from 'components';
import { useModalControl } from 'hooks';
import { PropsWithChildren, ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { colours } from 'system';
import UserMenu from './UserMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: theme.spacing(1.3, 4),
}));

const Drawer = styled(_Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 3,
}));

const LinkText = styled(Typography)<{ selected?: boolean }>(({ theme, selected }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(5),
  ...(selected ? { color: theme.palette.text.primary } : {}),
}));

const NavIcon = styled('span')<{ selected?: boolean }>(({ theme, selected }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
  ...(selected ? { color: colours.lightSlateBlue } : {}),
}));

const NavItem = ({ to, icon, children }: PropsWithChildren<{ to: string; icon?: ReactNode }>) => {
  const { pathname } = useLocation();
  const selected = pathname.endsWith(to);

  return (
    <Link component={RouterLink} {...{ to, selected }} sx={{ textDecoration: 'none' }}>
      <LinkText selected={selected}>
        {icon && <NavIcon selected={selected}>{icon}</NavIcon>}
        {children}
      </LinkText>
    </Link>
  );
};

export default function SiteNav() {
  const { user } = useAuth();
  const [showDrawer, hideDrawer, drawerOpen] = useModalControl();

  const navItems = [
    {
      to: '/',
      children: 'Dashboard',
      icon: <Icons.Dashboard />,
    },
    {
      to: '/users',
      children: 'Users',
      icon: <Icons.People />,
      groups: ['site_admin'],
    },
    {
      to: '/accounts',
      children: 'Accounts',
      icon: <Icons.Diamond />,
      groups: ['site_admin', 'account_admin', 'site_user'],
    },
    {
      to: '/onboarding',
      children: 'Onboarding',
      icon: <Icons.FlightTakeoff />,
      groups: ['site_admin'],
    },
    {
      to: '/finance',
      children: 'Finance Tools',
      icon: <Icons.AttachMoney />,
      groups: ['site_admin', 'finance_user'],
    },
    {
      to: '/dev',
      children: 'Dev Tools',
      icon: <Icons.Terminal />,
      groups: ['site_admin', 'dev_user'],
    },
  ];

  const NavItems = ({ user }: { user: AuthUser }) => (
    <>
      {navItems
        .filter(({ groups }) => !groups || user.groups.some((group) => groups.includes(group)))
        .map((item) => (
          <NavItem key={item.to} {...item} />
        ))}
    </>
  );

  return (
    <StyledAppBar position="fixed" elevation={0}>
      <StyledToolbar disableGutters sx={{ justifyContent: { xs: 'space-between', sm: 'inherit' } }}>
        <IconButton onClick={showDrawer} sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}>
          <Icons.Menu />
        </IconButton>
        <Logo color="primary" sx={{ mr: 4 }} href={''} />
        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'inherit' }, flexGrow: 1 }}>
          {user ? <NavItems user={user} /> : <Typography variant="h5">System Portal</Typography>}
        </Stack>
        <Drawer anchor="left" open={drawerOpen} onClose={hideDrawer}>
          <Box role="presentation" onClick={hideDrawer} onKeyDown={hideDrawer} sx={{ p: 2 }}>
            <Stack>{user && <NavItems user={user} />}</Stack>
          </Box>
        </Drawer>
        <UserMenu />
      </StyledToolbar>
    </StyledAppBar>
  );
}
