import { Button as _Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import { forwardRef } from 'react';

export type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

const Button = styled(_Button)({
  fontWeight: 500,
  fontSize: 14,
});

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading = false, children, disabled, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} disabled={disabled || loading} disableElevation>
        {loading ? <CircularProgress size={24} /> : children}
      </Button>
    );
  }
);

export default LoadingButton;
