import { ReactiveVar, useReactiveVar } from '@apollo/client';
import {
  Paper as MUIPaper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination as MUITablePagination,
  TablePaginationProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider, useTheme } from '@mui/styles';
import { EnhancedTableState } from 'cache';
import { useModalControl } from 'hooks';
import { ChangeEvent, FC, Fragment, MouseEvent, ReactNode, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { skipProps, spreadIf } from 'system';
import { Column, GlobalFilter, Order, Row } from '../types';
import EnhancedRow from './EnhancedRow';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import ExpandableRow from './ExpandableRow';
import { ExpandCollapseButton } from './ExpandCollapseButton';
import SimpleTableHead from './SimpleTableHead';
import {
  generateFilteredColumnsForColumn,
  generateFilteredColumnsForValue,
  generateGlobalFilterExcluded,
} from './utils';

const Root = styled('div')({ width: '100%' });
const Paper = styled(
  MUIPaper,
  skipProps('showPagination')
)<{ showPagination?: boolean }>(({ theme, showPagination }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: showPagination ? `1px solid ${theme.palette.divider}` : 'none',
}));

const TablePagination = styled((props: TablePaginationProps) => (
  <MUITablePagination {...props} component="div" />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export type EnhancedDesktopTableProps<TRow extends Row> = {
  rows: TRow[];
  columns: Column<TRow>[];
  onRowClick?: (id: string) => void;
  allowPagination?: boolean;
  allowSearch?: boolean;
  allowSort?: boolean;
  allowExport?: boolean;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode;
  footerComponent?: ReactNode;
  rowExpansionComponent?: FC<{ row: TRow }>;
  globalFilter?: GlobalFilter<TRow>;
  loading?: boolean;
};

export default function EnhancedDesktopTable<TRow extends Row>({
  rows,
  columns: allColumns,
  onRowClick,
  allowPagination = false,
  allowSearch = false,
  allowSort = false,
  allowExport = false,
  reactiveState,
  toolbarComponent,
  footerComponent,
  rowExpansionComponent: expansionComponent,
  globalFilter,
  loading,
}: EnhancedDesktopTableProps<TRow>) {
  const state = useReactiveVar(reactiveState);
  const [handleExpand, handleCollapse, rowExpanded, expandedRowId] = useModalControl<Row['id']>();
  const { page, rowsPerPage, search, order, orderBy, filteredColumns, globalFilterExcluded } =
    state;

  const columns = useMemo<Column<TRow>[]>(
    () => [
      ...(expansionComponent
        ? [
            {
              flex: '0 72px',
              headerName: '',
              disableSort: true,
              field: 'toggle-row-expansion',
              renderCell: ({ id }: { id: string }) => (
                <ExpandCollapseButton
                  {...{
                    onCollapse: handleCollapse,
                    onExpand: () => handleExpand(id),
                    expanded: rowExpanded && expandedRowId === id,
                  }}
                />
              ),
            },
          ]
        : []),
      ...allColumns.map((c) => ({ ...c, hidden: c.hideDesktop || c.hidden })),
    ],
    [allColumns, expansionComponent, rowExpanded, expandedRowId]
  );

  const themeInstance = useTheme();

  const toPlainText = (node: ReactNode) => {
    const divContainer = document.createElement('div');
    divContainer.innerHTML = ReactDOMServer.renderToString(
      <ThemeProvider theme={themeInstance}>{node}</ThemeProvider>
    );
    return divContainer.textContent || divContainer.innerText || '';
  };

  const renderCsvCell = <TRow extends Row = Row>(row: TRow, column: Column<TRow>) => {
    const renderCell =
      column?.renderCsvCell ?? column?.renderCell ?? (() => <>{row[column.field]}</>);

    return toPlainText(
      renderCell({
        id: row.id,
        value: row[column.field],
        row,
      })
    );
  };

  const onRequestSort = (event: MouseEvent, property: string, sortField?: string) => {
    reactiveState({
      ...state,
      order: orderBy === property && order === Order.ASC ? Order.DESC : Order.ASC,
      orderBy: property,
      sortField,
      page: 0,
    });
  };

  const exportCsv = () => {
    const data = new Blob(
      [
        [
          columns.map(({ headerName }) => `"${headerName}"`).join(','),
          ...rows.map((row) =>
            columns
              .map((column) => renderCsvCell(row, column))
              .map((v) => (typeof v === 'number' ? v : `"${v}"`))
          ),
        ].join('\n'),
      ],
      { type: 'text/csv' }
    );
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return (
    <Root>
      <Paper showPagination={allowPagination} elevation={0}>
        <TableContainer>
          {Boolean(allowSearch || toolbarComponent) && (
            <EnhancedTableToolbar
              {...{
                allowSearch,
                search,
                updateSearch: (search: string) => reactiveState({ ...state, search, page: 0 }),
                toolbarComponent,
                onExportCsv: allowExport ? exportCsv : undefined,
              }}
            />
          )}
          {allowPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
              count={rows.length}
              {...{
                rowsPerPage,
                page,
                onPageChange: (_event: unknown, newPage: number) => {
                  reactiveState({ ...state, page: newPage });
                },
                onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => {
                  reactiveState({
                    ...state,
                    page: 0,
                    rowsPerPage: parseInt(event.target.value, 10),
                  });
                },
              }}
            />
          )}
          <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            {allowSort ? (
              <EnhancedTableHead<TRow>
                {...{
                  columns,
                  filteredColumns,
                  order,
                  orderBy,
                  onRequestSort,
                  onFilteredColumnAllChange: (field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForColumn(field, filteredColumns, []),
                    });
                  },
                  onFilteredColumnNoneChange: (field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForColumn(
                        field,
                        filteredColumns,
                        columns
                          .filter(({ hidden }) => !hidden)
                          .find((column) => column.field === field)?.filterColumnValues ?? []
                      ),
                    });
                  },
                  onFilteredColumnChange: (value: string, toExclude: boolean, field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForValue(
                        value,
                        toExclude,
                        field,
                        filteredColumns
                      ),
                    });
                  },
                  globalFilter,
                  globalFilterExcluded,
                  onGlobalFilterChange: (value: string, toExclude: boolean) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      globalFilterExcluded: generateGlobalFilterExcluded(
                        value,
                        toExclude,
                        globalFilterExcluded
                      ),
                    });
                  },
                }}
              />
            ) : (
              <SimpleTableHead<TRow>
                {...{
                  columns,
                  globalFilter,
                  globalFilterExcluded,
                  onGlobalFilterChange: (value: string, toExclude: boolean) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      globalFilterExcluded: generateGlobalFilterExcluded(
                        value,
                        toExclude,
                        globalFilterExcluded
                      ),
                    });
                  },
                }}
              />
            )}
            <TableBody>
              {((loading ? [{ id: '1' }, { id: '2' }, { id: '3' }] : rows) as TRow[])
                .slice(
                  allowPagination ? page * rowsPerPage : 0,
                  allowPagination ? page * rowsPerPage + rowsPerPage : rows.length
                )
                .map((row) => (
                  <Fragment key={row.id}>
                    <EnhancedRow<TRow> {...{ row, onRowClick, columns, loading }} />
                    {expansionComponent && (
                      <ExpandableRow<TRow>
                        row={row}
                        {...spreadIf(expansionComponent, { expansionComponent })}
                        expanded={rowExpanded && expandedRowId === row.id}
                      />
                    )}
                  </Fragment>
                ))}
            </TableBody>

            {footerComponent && (
              <TableFooter>
                <tr>
                  <td colSpan={99}>{footerComponent}</td>
                </tr>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        {allowPagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
            count={rows.length}
            {...{
              rowsPerPage,
              page,
              onPageChange: (_event: unknown, newPage: number) => {
                reactiveState({ ...state, page: newPage });
              },
              onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => {
                reactiveState({ ...state, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
              },
            }}
          />
        )}
      </Paper>
    </Root>
  );
}
