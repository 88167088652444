import { Check, Close } from '@mui/icons-material';
import {
  CircularProgress,
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
} from '@mui/material';

type ValidationListItemProps = Omit<ListItemProps, 'children' | 'secondaryAction'> & {
  valid?: boolean;
  children: ListItemTextProps['primary'];
  loading?: boolean;
};

export const ValidationListItem = ({
  valid,
  loading,
  children,
  ...props
}: ValidationListItemProps) => {
  return (
    <ListItem
      {...props}
      secondaryAction={
        loading ? (
          <CircularProgress size="1.3rem" />
        ) : valid ? (
          <Check color="success" />
        ) : (
          <Close color="error" />
        )
      }
    >
      <ListItemText primary={children} />
    </ListItem>
  );
};
