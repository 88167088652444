import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { ReactNode } from 'react';
import { skipProps } from 'system';

type TableHeadCellProps = {
  flex: CSSProperties['flex'];
  children: ReactNode;
  minWidth?: number;
  margin?: number;
  className?: string;
};

const Cell = styled(
  TableCell,
  skipProps('minWidth')
)<{
  flex: CSSProperties['flex'];
  margin?: number;
  minWidth?: CSSProperties['minWidth'];
}>(({ theme, flex, margin, minWidth }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  border: 'none',
  margin: theme.spacing(2, 1, 2, margin ?? 2),
  padding: 0,
  flex,
  minWidth,
}));

export default function TableHeadCell({
  flex,
  children,
  minWidth,
  margin,
  className,
}: TableHeadCellProps) {
  return (
    <Cell {...{ flex, minWidth, margin, className }} align="left" padding="normal">
      {children}
    </Cell>
  );
}
