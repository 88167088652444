import { Paper, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentClass, FunctionComponent } from 'react';

const TitlePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(4),
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled(Typography)<{ withMargin?: boolean }>(({ theme, withMargin }) => ({
  fontWeight: 'bold',
  ...(withMargin ? { marginBottom: theme.spacing(4) } : {}),
}));

export default function PageTitle(
  props: TypographyProps & {
    component?: string | ComponentClass | FunctionComponent;
    title: string;
    noPaper?: boolean;
  }
) {
  const { children, variant = 'h4', component = 'h1', title, noPaper = false, ...rest } = props;
  if (noPaper) {
    return (
      <div>
        <Title withMargin {...{ variant, component, ...rest }}>
          {title}
        </Title>
        {children}
      </div>
    );
  }
  return (
    <TitlePaper elevation={0}>
      <Title {...{ variant, component, ...rest }}>{title}</Title>
      {children}
    </TitlePaper>
  );
}
