import { InMemoryCache, Reference } from '@apollo/client';
import {
  HandlerMap,
  guardHandler,
  isSystemAutopayFeeAddedEvent,
  isSystemAutopayStartedEvent,
  isSystemAutopayStatusUpdatedEvent,
  isSystemScanCompletedEvent,
} from '@propra-system/registry';
import { invalidate } from 'system';

export const cacheHandlers = (cache: InMemoryCache): HandlerMap =>
  [
    guardHandler(isSystemScanCompletedEvent, () =>
      Promise.resolve(
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            scannerResults: invalidate,
          },
        })
      )
    ),
    guardHandler(isSystemAutopayStartedEvent, ({ detail }) =>
      Promise.resolve(
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            listAutopays: (existing?: { items: Reference[] }) =>
              existing?.items &&
              existing.items.some(({ __ref }) => __ref === `Autopay:${detail.autopayId}`)
                ? existing
                : {
                    ...existing,
                    items: [...(existing?.items ?? []), { __ref: `Autopay:${detail.autopayId}` }],
                  },
          },
        })
      )
    ),
    guardHandler(isSystemAutopayStatusUpdatedEvent, ({ detail }) =>
      Promise.resolve(
        cache.modify({
          id: cache.identify({ __typename: 'Autopay', id: detail.autopayId }),
          fields: {
            status: () => detail.status,
            taskToken: () => detail.taskToken,
          },
        })
      )
    ),
    guardHandler(isSystemAutopayFeeAddedEvent, ({ detail }) =>
      Promise.resolve(
        cache.modify({
          id: cache.identify({ __typename: 'Autopay', id: detail.autopayId }),
          fields: {
            nsfCount: invalidate,
            listFees: invalidate,
          },
        })
      )
    ),
  ] as HandlerMap;
