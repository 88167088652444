import { TabContext } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, InfoIcon, Tab, TabList, TabPanel } from './Tabs.styles';

type TabsProps = {
  initialValue: string;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  isVertical?: boolean;
  noBottomBorder?: boolean;
  onTabChange?: (newTab: string) => void;
  tabs: {
    value: string;
    label: string;
    tooltip?: string;
    showTooltip?: boolean;
    render: ReactNode;
    disabled?: boolean;
    hidden?: boolean;
    order?: number;
  }[];
};

const keepTab = (state: unknown) => (state as { keepTab?: boolean })?.keepTab;

export default function Tabs({
  initialValue,
  tabs,
  variant = 'standard',
  isVertical = false,
  noBottomBorder = false,
  onTabChange,
}: TabsProps) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(initialValue);
  const handleChange = (_event: ChangeEvent<unknown>, newTab: string) => {
    setSelectedTab(newTab);
    onTabChange?.(newTab);
  };

  useEffect(() => {
    if (!keepTab(location.state)) setSelectedTab(initialValue);
  }, [initialValue, location]);

  const sortedTabs = tabs.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));

  const renderLabel = (label: string, showTooltip: boolean, tooltip: string) =>
    showTooltip ? (
      <span>
        {label}
        <Tooltip title={tooltip}>
          <InfoIcon />
        </Tooltip>
      </span>
    ) : (
      label
    );

  return (
    <TabContext value={selectedTab}>
      {!isVertical ? (
        <AppBar position="static" color="transparent" elevation={0}>
          <TabList
            onChange={handleChange}
            variant={variant}
            indicatorColor="primary"
            TabIndicatorProps={{ children: <span /> }}
            className={noBottomBorder ? 'noBottom' : ''}
          >
            {sortedTabs
              .filter((tab) => !tab.hidden)
              .map((tab) => (
                <Tab
                  key={tab.value}
                  label={renderLabel(tab.label, Boolean(tab.showTooltip), tab.tooltip ?? '')}
                  value={tab.value}
                  disabled={Boolean(tab.disabled)}
                />
              ))}
          </TabList>
        </AppBar>
      ) : (
        <TabList
          onChange={handleChange}
          variant={variant}
          indicatorColor="primary"
          TabIndicatorProps={{ children: <span /> }}
          orientation="vertical"
          className="vertical"
        >
          {sortedTabs
            .filter((tab) => !tab.hidden)
            .map((tab) => (
              <Tab
                key={tab.value}
                label={renderLabel(tab.label, Boolean(tab.showTooltip), tab.tooltip ?? '')}
                value={tab.value}
                disabled={Boolean(tab.disabled)}
              />
            ))}
        </TabList>
      )}
      {sortedTabs.map((tab) => (
        <TabPanel key={tab.value} value={tab.value} className={isVertical ? 'vertical' : ''}>
          {tab.render}
        </TabPanel>
      ))}
    </TabContext>
  );
}
