import { Paper as _Paper, PaperProps, styled } from '@mui/material';

const Paper = styled(_Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
}));

export default function FullSizePaper({ children, ...props }: PaperProps) {
  return (
    <Paper {...props} elevation={0}>
      {children}
    </Paper>
  );
}
