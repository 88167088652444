import { Button, Toolbar as MUIToolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SearchBar } from 'components';
import { Csv } from 'components/icons';
import { ReactNode } from 'react';

type EnhancedTableToolbarProps = {
  allowSearch: boolean;
  onExportCsv?: VoidFunction;
  toolbarComponent?: ReactNode;
  search?: string;
  updateSearch: (search: string) => void;
};

const ExportToolbar = styled(MUIToolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 0),
  minHeight: 0,
}));

const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export default function EnhancedTableToolbar({
  allowSearch,
  onExportCsv,
  search,
  updateSearch,
  toolbarComponent,
}: EnhancedTableToolbarProps) {
  return (
    <>
      {onExportCsv && (
        <ExportToolbar>
          <Button
            startIcon={<Csv />}
            onClick={(e) => {
              e.stopPropagation();
              onExportCsv();
            }}
            disabled={false}
          ></Button>
        </ExportToolbar>
      )}
      <Toolbar>
        {allowSearch && (
          <SearchBar placeholder="Search" initialSearch={search} onSearchEntered={updateSearch} />
        )}
        {toolbarComponent}
      </Toolbar>
    </>
  );
}
