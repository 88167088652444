import { useTheme } from 'context';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

const env = (process.env.REACT_APP_ENV ?? 'dev').toLowerCase();

export default function Debug({
  src = {},
  ...props
}: Omit<ReactJsonViewProps, 'src'> & { src?: ReactJsonViewProps['src'] }) {
  const { darkMode } = useTheme();
  return env === 'prod' ? (
    <></>
  ) : (
    <ReactJson collapsed theme={darkMode ? 'bright' : 'bright:inverted'} src={src} {...props} />
  );
}
