import { Button as MUIButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { skipProps } from 'system';

export type ActionCellProps = {
  id: string;
  icon: ReactNode;
  name: string;
  onClick: (id: string) => void;
  disabled?: boolean;
  float?: boolean;
};

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const ActionText = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '1em',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8em',
  },
}));

const Button = styled(
  MUIButton,
  skipProps('float')
)<{ float?: boolean }>(({ theme, float }) => ({
  display: 'flex',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(2),
    alignItems: 'center',
  },
  ...(float
    ? {
        float: 'right',
        [theme.breakpoints.down('lg')]: {
          float: 'none',
        },
      }
    : {}),
}));

export default function ActionsCell({
  actions,
  stopPropagation = false,
}: {
  actions: ActionCellProps[];
  stopPropagation?: boolean;
}) {
  return (
    <Container>
      {actions.map(({ id, icon, name, onClick, disabled = false, float = false }) => (
        <Button
          key={`action-${name}-${id}`}
          startIcon={icon}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
            onClick(id);
          }}
          {...{ float, disabled }}
        >
          <ActionText>{name}</ActionText>
        </Button>
      ))}
    </Container>
  );
}
