import { Row } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { filterByValue } from 'system';

export const useSearchRows = <TRow extends Row = Row>({
  rows,
  minChars = 0,
  searchTerm = '',
}: {
  rows: TRow[];
  minChars?: number;
  searchTerm?: string;
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const searchedRows = useMemo<TRow[]>(
    () => (localSearchTerm?.length > minChars ? filterByValue(rows, localSearchTerm) : rows),
    [localSearchTerm, rows, minChars]
  );

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  return {
    searchTerm: localSearchTerm,
    searchedRows,
    setSearchTerm: setLocalSearchTerm,
    clearSearch: () => setLocalSearchTerm(''),
  };
};
