import { SvgIcon, SvgIconProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { colours } from 'system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& path': {
        color: theme.palette.tertiary.main,
      },
    },
  })
);

export default function Owners({ className, ...props }: SvgIconProps) {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 65.02 60.37" className={clsx(className, classes.root)} {...props}>
      <path
        fill={colours.shamrock}
        d="M78,55a49.35,49.35,0,0,0-11.23-1.51c-9.28,0-27.86,4.64-27.86,13.93v9.29H71.29A19,19,0,0,1,78,55ZM52.86,30.25A13.91,13.91,0,1,0,66.74,16.31,13.87,13.87,0,0,0,52.86,30.25Z"
        transform="translate(-15.66 -16.31)"
      />
      <path
        fill={colours.waterLeaf}
        d="M29.63,30.25A13.92,13.92,0,0,0,47.75,43.53a23.39,23.39,0,0,1-4.18-13.28A22.91,22.91,0,0,1,47.75,17,13.92,13.92,0,0,0,29.63,30.25Z"
        transform="translate(-15.66 -16.31)"
      />
      <path
        fill={colours.waterLeaf}
        d="M29.59,67.4c0-5.48,2.55-9.8,6.41-13.19-9.34,1.62-20.34,6-20.34,13.19v9.29H29.59Z"
        transform="translate(-15.66 -16.31)"
      />
    </SvgIcon>
  );
}
