import { ApolloError } from '@apollo/client';
import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { ReactNode } from 'react';

export type AdaptiveColumn = {
  field: string;
  headerName: string;
  flex?: CSSProperties['flex'];
  className?: string;
  sx?: SxProps<Theme>;
  headerClassName?: string;
  headerSx?: SxProps<Theme>;
  minWidth?: number;
  headerMargin?: number;
  hidden?: boolean;
  renderCell?: (params: { id: string; value: unknown; row: Row }) => ReactNode;
};

export type AdaptiveTableProps = {
  rows: Row[];
  columns: AdaptiveColumn[];
  onRowClick?: (id: string, row: Row) => void;
  loading?: boolean;
  error?: string | ApolloError;
  toolbarComponent?: ReactNode;
  footerComponent?: ReactNode;
  className?: string;
  header?: 'none' | 'mobileOnly' | 'desktopOnly' | 'always';
  cellPadding?: number;
  hasMargins?: boolean;
  condensed?: boolean;
};

export type Row = {
  id: string;
  [key: string]: unknown;
};

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type Column<TRow = Row> = {
  field: string & keyof TRow;
  sortField?: string;
  headerName: string;
  flex?: CSSProperties['flex'];
  className?: string;
  filterColumnValues?: string[];
  renderCsvCell?: (params: { id: string; value: unknown; row?: TRow }) => ReactNode;
  renderCell?: (params: { id: string; value: unknown; row?: TRow }) => ReactNode;
  hideDesktop?: boolean;
  hideMobile?: boolean;
  disableSort?: boolean;
  hidden?: boolean;
};

export type GlobalFilter<TRow = Row> = { label: string; test: (row: TRow) => boolean }[];
