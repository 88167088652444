import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDebounce } from 'hooks';
import { ChangeEvent, useEffect, useState } from 'react';

const SearchTextField = styled(TextField)(({ theme }) => ({
  maxWidth: 650,
  background: theme.palette.background.paper,
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

type SearchBarProps = {
  placeholder: string;
  onSearchEntered: (search: string) => void;
  initialSearch?: string;
};

export default function SearchBar({
  placeholder,
  onSearchEntered,
  initialSearch = '',
}: SearchBarProps) {
  const [search, setSearch] = useState<string | undefined>(initialSearch);
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    onSearchEntered(debouncedSearch ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <SearchTextField
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      value={search}
      onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton size="large">
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {search && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearch('')}
                  onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                  edge="end"
                  size="large"
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
}
