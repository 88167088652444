import { FilteredColumn } from 'cache';
import { get, toNumber } from 'lodash';
import { Order, Row } from '../types';

function descendingComparator<TRowA extends Row = Row, TRowB = TRowA>(
  a: TRowA,
  b: TRowB,
  orderBy: string
) {
  const aOrder = get(a, orderBy);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const bOrder = get(b, orderBy);

  if (!isNaN(toNumber(aOrder)) && !isNaN(toNumber(bOrder))) {
    return toNumber(bOrder) - toNumber(aOrder);
  }

  return String(bOrder).localeCompare(String(aOrder));
}

export function getComparator<TRowA extends Row, TRowB = TRowA>(
  order: Order,
  orderBy: string
): (a: TRowA, b: TRowB) => number {
  return order === Order.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<TRow extends Row = Row>(
  array: TRow[],
  comparator: (a: TRow, b: TRow) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [TRow, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const getFilteredColumn = (
  field: string,
  filteredColumns: FilteredColumn[]
): FilteredColumn | undefined => {
  return filteredColumns.find((filteredColumn) => filteredColumn.field === field);
};

export const generateFilteredColumnsForValue = (
  value: string,
  toExclude: boolean,
  field: string,
  filteredColumns: FilteredColumn[]
) => {
  if (getFilteredColumn(field, filteredColumns)) {
    return filteredColumns.map((filteredColumn) =>
      filteredColumn.field === field
        ? {
            ...filteredColumn,
            excludes: toExclude
              ? filteredColumn.excludes.filter((excludedValue) => excludedValue !== value)
              : [...filteredColumn.excludes, value],
          }
        : filteredColumn
    );
  } else {
    return [
      ...filteredColumns,
      {
        field,
        excludes: [value],
      },
    ];
  }
};

export const generateFilteredColumnsForColumn = (
  field: string,
  filteredColumns: FilteredColumn[],
  values: string[]
) => {
  if (getFilteredColumn(field, filteredColumns)) {
    return filteredColumns.map((filteredColumn) =>
      filteredColumn.field === field
        ? {
            ...filteredColumn,
            excludes: values,
          }
        : filteredColumn
    );
  } else {
    return [
      ...filteredColumns,
      {
        field,
        excludes: values,
      },
    ];
  }
};

export const generateGlobalFilterExcluded = (
  value: string,
  toExclude: boolean,
  globalFilterExcluded: string[]
) => {
  return toExclude
    ? [...globalFilterExcluded, value]
    : globalFilterExcluded.filter((excludedValue) => excludedValue !== value);
};
