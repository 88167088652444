import { get } from 'lodash';
import { Country, Provinces, TimezonesByCountry, UnionToIntersection } from 'system/types';
import { Place } from '../hooks/useGoogleAutocompletePredictions';

export const validCountry = (country: unknown): country is Country =>
  typeof country === 'string' && Object.keys(Country).includes(country);

export const getTimezone = (country: Country, province = '') => {
  type FlattenedTimezones = UnionToIntersection<(typeof TimezonesByCountry)[typeof country]>;

  const timezones = TimezonesByCountry[country];

  const prov = Object.keys(timezones).includes(province)
    ? (province as keyof FlattenedTimezones)
    : undefined;

  return prov ? (get(timezones as FlattenedTimezones, [prov]) ?? null) : null;
};
export const provinceLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'State' : 'Province';

export const postalCodeLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'Zip Code' : 'Postal Code';

const ProvinceNames: Record<string, string> = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const provinceName = (province?: string) =>
  province ? ProvinceNames[province.toUpperCase()] : (province ?? province);

export const DEFAULT_GST = 0.05;

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const getCondoLabels = (province: keyof typeof Provinces | string | undefined) =>
  province === Provinces.BC
    ? {
        condo: 'strata',
        unitFactor: 'unit entitlement',
      }
    : {
        condo: 'condo',
        unitFactor: 'unit factor',
      };

export const placeToAddress = (place?: Place, country: Country = Country.CA) => {
  const timezone = getTimezone(country, place?.province);

  return {
    ...(place?.streetName && { street: place.streetName }),
    ...(place?.city && { city: place.city }),
    ...(place?.postalCode && { postal: place.postalCode }),
    ...(place?.lat && { lat: place.lat }),
    ...(place?.lng && { lng: place.lng }),
    ...(place?.province && { province: place.province }),
    ...(timezone && { timezone }),
    ...(country && { country }),
  };
};
