import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { GuardHandler, HandlerMap, emptyContext, handleRecord } from '@propra-system/registry';
import { SystemEvent } from 'api/system';
import { useEffect, useState } from 'react';
import { hashCode } from 'system';
import { z } from 'zod';
import { cacheHandlers } from './cacheHandlers';

export const useEventHandlers = ({
  systemClient,
}: {
  systemClient: ApolloClient<NormalizedCacheObject>;
}) => {
  const [event, setEvent] = useState<SystemEvent>();
  const defaultHandlers = systemClient ? cacheHandlers(systemClient?.cache as InMemoryCache) : [];
  const [handlerMap, setHandlerMap] = useState<HandlerMap>(defaultHandlers);
  const [hashMap, setHashMap] = useState<number[]>(
    defaultHandlers.map((gh) => hashCode(gh.map((fn) => fn.toString())))
  );

  const addHandler = (guardHandler: GuardHandler) => {
    const hash = hashCode(guardHandler.map((fn) => fn.toString()));
    if (!hashMap.includes(hash)) {
      setHashMap((existing) => [...existing, hash]);
      setHandlerMap((map) => [...map, guardHandler]);
    }
  };

  const addHandlerMap = (map: HandlerMap) => map.forEach(addHandler);

  const eventShape = z.object({
    id: z.string(),
    time: z.string(),
    source: z.string(),
    detailType: z.string(),
    detail: z.string().transform((s) => {
      const parsedDetail = z.object({}).passthrough().safeParse(JSON.parse(s));
      return parsedDetail.success ? parsedDetail.data : z.NEVER;
    }),
  });

  useEffect(() => {
    const parsedEvent = eventShape.safeParse(event);
    if (parsedEvent.success) {
      void handleRecord(handlerMap, parsedEvent.data, emptyContext(), {
        errorOnNoMatch: false,
      });
    }
  }, [event, handleRecord]);

  return { setEvent, addHandler, addHandlerMap, hashMap, handlerMap };
};
