import { Cached } from '@mui/icons-material';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import { useCallback, useState } from 'react';

type RefetchButtonProps = {
  refetch: () => Promise<unknown>;
} & Omit<IconButtonProps, 'onClick'>;

export const RefetchButton = ({ refetch, disabled, ...props }: RefetchButtonProps) => {
  const [refetching, setRefetching] = useState(false);
  const refetchWrapper = useCallback(async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  }, [refetch]);

  return (
    <IconButton
      title="Refresh data"
      {...props}
      onClick={() => refetchWrapper()}
      disabled={refetching || disabled}
    >
      {refetching ? <CircularProgress size="1em" /> : <Cached />}
    </IconButton>
  );
};
