import { SvgIcon, SvgIconProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& path': {
        color: theme.palette.tertiary.main,
      },
    },
  })
);

export default function Region({ className, ...props }: SvgIconProps) {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 20 20" className={clsx(className, classes.root)} {...props}>
      <path d="M11.0195 17.9305V19.9505C13.0295 19.7505 14.8595 18.9505 16.3395 17.7405L14.9195 16.3105C13.8095 17.1705 12.4795 17.7505 11.0195 17.9305Z" />
      <path d="M2.0293 10.0008C2.0293 5.95078 5.0593 2.59078 8.9793 2.07078V0.0507812C3.9493 0.580781 0.0292969 4.84078 0.0292969 10.0008C0.0292969 15.1608 3.9493 19.4208 8.9793 19.9508V17.9308C5.0593 17.4108 2.0293 14.0508 2.0293 10.0008Z" />
      <path d="M17.9501 9.00164H19.9701C19.7701 6.99164 18.9701 5.16164 17.7601 3.68164L16.3301 5.11164C17.1901 6.21164 17.7701 7.54164 17.9501 9.00164Z" />
      <path d="M16.3395 2.26078C14.8595 1.05078 13.0195 0.250781 11.0195 0.0507812V2.07078C12.4795 2.25078 13.8095 2.83078 14.9195 3.69078L16.3395 2.26078Z" />
      <path d="M16.3301 14.9L17.7601 16.32C18.9701 14.84 19.7701 13.01 19.9701 11H17.9501C17.7701 12.46 17.1901 13.79 16.3301 14.9Z" />
      <path d="M14 9.1C14 6.61 12.1 5 10 5C7.9 5 6 6.61 6 9.1C6 10.76 7.33 12.73 10 15C12.67 12.73 14 10.76 14 9.1ZM10 10C9.41 10 8.93 9.52 8.93 8.93C8.93 8.34 9.41 7.86 10 7.86C10.59 7.86 11.07 8.34 11.07 8.93C11.07 9.52 10.59 10 10 10Z" />
    </SvgIcon>
  );
}
