import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const FullHeightGrid = styled(Grid)({
  height: '100vh',
});

export default function FullScreenSpinner({ size = 240 }: { size?: number }) {
  return (
    <FullHeightGrid container justifyContent="center" alignItems="center">
      <CircularProgress size={size} />
    </FullHeightGrid>
  );
}
