import { yupResolver } from '@hookform/resolvers/yup';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode } from 'react';
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { ValidateOptions } from 'yup/lib/types';
import { ZodType, z } from 'zod';

export type FormProps<T extends FieldValues, Z extends z.ZodTypeAny> = {
  children: ReactNode;
  defaultValues?: DeepPartial<T>;
  onSubmit?: SubmitHandler<T>;
  className?: string;
  shouldUnregister?: boolean;
  schema?: AnyObjectSchema | Z;
  schemaOptions?: ValidateOptions<unknown>;
};
export default function Form<T extends FieldValues, Z extends z.ZodTypeAny = never>({
  children,
  defaultValues,
  onSubmit = () => null,
  shouldUnregister,
  schema,
  schemaOptions,
  ...props
}: FormProps<T, Z>) {
  const resolver = schema
    ? schema instanceof ZodType
      ? zodResolver(schema)
      : yupResolver(schema, schemaOptions)
    : undefined;

  const methods = useForm<T>({
    ...(resolver && { resolver }),
    mode: 'onChange',
    defaultValues,
    shouldUnregister,
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
