import { RouteProps } from 'navigation/types';
import { lazy } from 'react';

export const routes: RouteProps[] = [
  {
    path: '/login',
    page: lazy(() => import('./login')),
    frame: 'CenteredFrame',
    anonymous: true,
  },
  {
    path: '/',
    page: lazy(() => import('./dashboard')),
    exact: true,
  },
  {
    path: '/users/*',
    page: lazy(() => import('./users')),
    exact: true,
    memberOfSomeGroup: ['site_admin'],
  },
  {
    path: '/accounts/*',
    page: lazy(() => import('./accounts')),
    exact: true,
    memberOfSomeGroup: ['site_admin', 'account_admin', 'site_user'],
  },
  {
    path: '/finance/*',
    page: lazy(() => import('./finance')),
    exact: true,
    memberOfSomeGroup: ['site_admin', 'finance_user'],
  },
  {
    path: '/onboarding/*',
    page: lazy(() => import('./onboarding')),
    exact: true,
    memberOfSomeGroup: ['site_admin', 'account_admin'],
  },
  {
    path: '/dev/*',
    page: lazy(() => import('./dev')),
    exact: true,
    memberOfSomeGroup: ['site_admin', 'dev_user'],
  },
  {
    path: '/*',
    page: lazy(() => import('./notfound')),
  },
];
