import { useAuth } from 'auth';
import { FullScreenSpinner } from 'components';
import { routes as pageRoutes } from 'pages/routes';
import { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import * as Frames from './frames';
import { DefaultFrame } from './frames';
import SiteNav from './SiteNav';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const from = useLocation();

  return user ? children : <Navigate to="/login" state={{ from }} replace />;
};

const SignOut = () => {
  const { signout } = useAuth();
  signout();
  return <Navigate to="/" replace />;
};

export default function Navigation() {
  const { user } = useAuth();
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <Routes>
        <Route path="/logout" element={<SignOut />} />
        {pageRoutes
          .filter(
            ({ memberOfSomeGroup }) =>
              !memberOfSomeGroup || memberOfSomeGroup.some((group) => user?.groups.includes(group))
          )
          .map(({ page: Page, frame, path, exact, anonymous }) => {
            const Frame = frame ? Frames[frame] : DefaultFrame;
            const framed = (
              <Frame nav={<SiteNav />}>
                <Page />
              </Frame>
            );

            return (
              <Route
                {...{ path, exact }}
                key={path}
                element={anonymous ? framed : <RequireAuth>{framed}</RequireAuth>}
              />
            );
          })}
      </Routes>
    </Suspense>
  );
}
