import { z } from 'zod';

export const coalesceTo =
  <TCoalesced>(to: TCoalesced) =>
  <TVal>(val: TVal): TCoalesced | NonNullable<TVal> =>
    val ?? to;

export const maybe = <TShape extends z.ZodTypeAny>(shape: TShape) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  shape.nullish().transform((x) => x ?? undefined);
