import { TableHead as MaterialTableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdaptiveColumn } from '../types';
import TableHeadCell from './TableHeadCell';

const Row = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function TableHead({ columns }: { columns: AdaptiveColumn[] }) {
  return (
    <MaterialTableHead>
      <Row>
        {columns.map((column) => (
          <TableHeadCell
            key={column.field}
            flex={column.flex ?? 1}
            minWidth={column.minWidth}
            margin={column.headerMargin}
            className={column.headerClassName}
          >
            {column.headerName}
          </TableHeadCell>
        ))}
      </Row>
    </MaterialTableHead>
  );
}
