import { Address, FractionInput } from 'api/manager';
import KSUID from 'ksuid';
import {
  castArray,
  compact,
  isEmpty,
  isEqual,
  isNil,
  isObject,
  map,
  mapValues,
  omitBy,
  reject,
} from 'lodash';
import { get } from 'lodash/fp';
import { DateTime, Interval, Zone } from 'luxon';
import { fraction, isFraction, multiply, number } from 'mathjs';
import { rrulestr } from 'rrule';
import { emptyArray, incentiveFeeId, rentFeeId } from './constant';
import { DateLike, Option } from './types';

export const noOp = () => {
  /* no-op */
};

export const sentenceCase = (sentence: string) =>
  sentence.replace(/[A-Za-z]/, (c) => c.toUpperCase());

export const titleCase = (snakeCase: string) =>
  map(snakeCase.replaceAll('_', ' ').split(' '), capitalize).join(' ');

export const capitalize = (name: string) =>
  ['hvac', 'gst', 'hst', 'pst', 'pad', 'eft']
    .reduce(
      (result, acronym) => result.replace(acronym.toLowerCase(), (match) => match.toUpperCase()),
      name.toLowerCase()
    )
    .replace(/^./, (c) => c.toUpperCase());

export const avatarInitials = (name = '') =>
  name
    .replace(/[^\w\s]/gi, '')
    .split(/[ ]+/)
    .filter((_w, i, arr) => i === 0 || i === arr.length - 1)
    .map((word) => word[0])
    .join('')
    .toUpperCase();

export const skipProps = (...props: string[]) => ({
  shouldForwardProp: (prop: string) => !props.includes(prop),
});

export const spreadIf = <P = unknown, T = unknown>(predicate: P, obj?: T) =>
  predicate ? (obj ? obj : predicate) : {};

export const spreadArrayIf = <P = unknown, T = unknown>(predicate: P, list?: T) =>
  predicate ? (list ? list : []) : [];

export const parseDates = <TDates extends unknown[]>(...dates: TDates) =>
  dates.map((date) =>
    DateTime.isDateTime(date)
      ? date
      : date instanceof Date
        ? DateTime.fromJSDate(date)
        : typeof date === 'string'
          ? DateTime.fromISO(date)
          : DateTime.invalid('invalid date')
  );

export const parseDatesWithTz = <TDates extends unknown[]>(
  zone: Zone | string = 'America/Edmonton',
  ...dates: TDates
) =>
  parseDates(
    ...dates.map((dateLike) =>
      typeof dateLike === 'string' && dateLike.length === 10
        ? DateTime.fromISO(dateLike, { zone }).toUTC()
        : dateLike
    )
  ).map((d) => d.setZone(zone));

export const parseJSON = (jsonString = ''): Record<string, unknown> => {
  try {
    return JSON.parse(jsonString) as Record<string, unknown>;
  } catch (e) {
    return {};
  }
};

export const isIntervalBetween =
  <TRecord extends { startZ?: string; endZ?: string }>(date: DateTime) =>
  ({ startZ, endZ }: TRecord) =>
    date <= DateTime.fromISO(endZ ?? 'invalid').endOf('day') &&
    date >= DateTime.fromISO(startZ ?? 'invalid').startOf('day');

export const toFlatPropertyMap = (
  obj: Record<string | number | symbol, unknown>
): Record<string | number | symbol, unknown> => {
  const flattenRecursive = (
    obj: Record<string | number | symbol, unknown> = {},
    parentProperty?: string,
    propertyMap: Record<string | number | symbol, unknown> = {}
  ) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}.${key}` : key;
      if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        flattenRecursive(value as Record<string | number | symbol, unknown>, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
};

export const isNilOrEmptyObject = (
  item: unknown
): item is null | undefined | Record<string, never> =>
  isNil(item) || (typeof item === 'object' && !Array.isArray(item) && isEmpty(item));

export const stripNonData = <T = unknown>(
  item: T
): T extends null | undefined | Record<string, never> ? undefined : T => {
  return (
    isNilOrEmptyObject(item)
      ? undefined
      : Array.isArray(item)
        ? reject(item.map(stripNonData), isNilOrEmptyObject)
        : isObject(item) && !DateTime.isDateTime(item)
          ? omitBy(
              mapValues(item, stripNonData),
              (value, key) => key === '__typename' || isNilOrEmptyObject(value)
            )
          : item
  ) as T extends null | undefined | Record<string, never> ? undefined : T;
};

export const ensureArray = <T = unknown[] | null | undefined>(arr?: T) =>
  arr ?? (emptyArray as unknown as NonNullable<T>);

export const invalidate = (_: unknown, { DELETE }: { DELETE: unknown }) => DELETE;

export const hashCode = (inputs: string[]) =>
  inputs
    .join('#')
    .split('')
    .reduce((a, c) => (Math.imul(31, a) + c.charCodeAt(0)) | 0, 0);

export const stringAsOption = (option: Option | string) =>
  typeof option === 'string' ? { id: option, text: option } : option;
export const optionString = (option: Option | string) =>
  typeof option === 'string' ? option : option.text;

export const safeSum = (...args: (number | number[])[]) =>
  args.flat().reduce((a, c) => safeRound(a + c), 0);

export const safeRound = (arg: number, digits = 2) =>
  Math.round(arg * Math.pow(10, digits)) / Math.pow(10, digits);

const avoidNegativeZero = (value: number) => (safeRound(value) === 0 ? 0 : safeRound(value));

export const formatCurrency = (
  rawAmount?: number | string | null,
  options?: {
    showDollarSign?: boolean;
    hideZeroCents?: boolean;
    negativeStyle?: 'default' | 'cr' | 'parens';
    hideZeroAmount?: boolean;
  }
): string => {
  if (rawAmount === undefined || rawAmount === null || rawAmount === '') {
    return '';
  }

  const {
    showDollarSign = true,
    hideZeroCents = false,
    negativeStyle = 'default',
    hideZeroAmount = false,
  } = options ?? {};
  const numericAmount = Number.isFinite(Number(rawAmount)) ? Number(rawAmount) : 0;
  const amount = avoidNegativeZero(numericAmount ?? 0);

  const prefix = negativeStyle === 'cr' && amount < 0 ? 'CR' : '';
  const diplayedAmount = prefix && amount < 0 ? -amount : amount;

  const formattedAmount = Intl.NumberFormat('en-CA', {
    ...(showDollarSign ? { style: 'currency', currency: 'CAD' } : {}),
    ...(negativeStyle === 'parens' ? { currencySign: 'accounting' } : {}),
    ...(hideZeroCents && Math.floor(diplayedAmount) === diplayedAmount
      ? { maximumFractionDigits: 0 }
      : {}),
    ...(!showDollarSign && !hideZeroCents
      ? { maximumFractionDigits: 2, minimumFractionDigits: 2 }
      : {}),
  }).format(diplayedAmount);

  const display = amount === 0 && hideZeroAmount ? '' : `${prefix}${formattedAmount}`;

  return display;
};

export const ifDifferent =
  <T = unknown>(newValue: T) =>
  (prev: T) =>
    isEqual(prev, newValue) ? prev : newValue;

/**
 * https://stackoverflow.com/a/73900724/3092891
 * Run concurrent promises with a maximum concurrency level
 * @param concurrency The number of concurrently running promises
 * @param funcs An array of functions that return promises
 * @returns a promise that resolves to an array of the resolved values from the promises returned by funcs
 */
export const concurrent = <V>(concurrency: number, funcs: (() => Promise<V>)[]): Promise<V[]> =>
  new Promise((res, rej) => {
    let idx = -1;
    const p: Promise<V>[] = [];
    for (let i = 0; i < Math.max(1, Math.min(concurrency, funcs.length)); i++) runPromise();
    function runPromise() {
      if (++idx < funcs.length) (p[p.length] = funcs[idx]()).then(runPromise).catch(rej);
      else if (idx === funcs.length) Promise.all(p).then(res).catch(rej);
    }
  });

export const safeSumBy = <
  TProp extends string,
  TRec extends Partial<Record<TProp, number | number[]>>,
>(
  prop: TProp,
  ...args: (TRec | TRec[])[]
) => safeSum(compact(args.flatMap((x) => castArray(x).flatMap(get(prop)))));

export const isCurrent = (residencyOrEffect?: { startZ: string; endZ?: string }) =>
  Boolean(
    residencyOrEffect &&
      (residencyOrEffect.endZ
        ? Interval.fromISO(`${residencyOrEffect.startZ}/${residencyOrEffect.endZ}`).contains(
            DateTime.now()
          )
        : +DateTime.fromISO(residencyOrEffect.startZ) <= +DateTime.now())
  );

export const isFuture = (residencyOrEffect?: { startZ: string }) =>
  residencyOrEffect && +DateTime.now() < +DateTime.fromISO(residencyOrEffect.startZ);

export const isPast = (residency?: { endZ?: string }) =>
  Boolean(residency?.endZ && +DateTime.fromISO(residency.endZ) <= +DateTime.now());

function nowFromStart(startZ?: string) {
  const realStart = startZ ?? DateTime.now().startOf('day').toISO();
  return DateTime.fromISO(realStart);
}

export function isCurrentSince(baseStartZ?: string) {
  const start = nowFromStart(baseStartZ);
  return (residencyOrEffect?: { startZ: string; endZ?: string }) =>
    Boolean(
      residencyOrEffect &&
        (residencyOrEffect.endZ
          ? Interval.fromISO(`${residencyOrEffect.startZ}/${residencyOrEffect.endZ}`).contains(
              start
            )
          : DateTime.fromISO(residencyOrEffect.startZ) <= start)
    );
}

export const isRentEffect = (effect?: { feeId?: string }) => effect?.feeId == rentFeeId;

export const isIncentiveEffect = (effect?: { feeId?: string }) => effect?.feeId == incentiveFeeId;

export const isFeeEffect = (effect?: { feeId?: string }) =>
  effect?.feeId != rentFeeId && effect?.feeId != incentiveFeeId;

export const nextRecurrence = (rrule?: string, after?: DateLike, includeAfter = false) => {
  const [afterDate] = parseDates(after ?? DateTime.now().startOf('day')).map((d) => d.toJSDate());
  return rrule ? DateTime.fromJSDate(rrulestr(rrule).after(afterDate, includeAfter)) : undefined;
};

export const getTermMonthsFromRRule = (rrule: string) => {
  return rrulestr(rrule).options.interval;
};

export const toMathFraction = (frac?: number | FractionInput | math.Fraction) =>
  frac
    ? typeof frac === 'number'
      ? fraction(frac)
      : isFraction(frac)
        ? frac
        : fraction(frac.numerator, frac.denominator)
    : fraction(0);

export const multiplyFractions = (
  x: number | FractionInput | math.Fraction = fraction(0),
  ...xs: (number | FractionInput | math.Fraction)[]
) => xs.map(toMathFraction).reduce((a, b) => multiply(a, b) as math.Fraction, toMathFraction(x));

export const netEffect = (
  effects: Array<{ effect: number }> = [],
  {
    taxPct = 0,
    shareFrac = fraction(1),
  }: { taxPct?: number; shareFrac?: number | FractionInput | math.Fraction } = {}
) =>
  safeRound(
    number(
      multiplyFractions(safeSumBy('effect', effects), toMathFraction(shareFrac), safeSum(1, taxPct))
    ),
    2
  );

export const formatAddress = (address?: Address) => {
  return [address?.suite, address?.street, address?.city, address?.province, address?.postal]
    .filter(Boolean)
    .join(', ');
};

export const ksuid = () => KSUID.randomSync().string;
